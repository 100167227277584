<template>
  <div class="quote-wrapper">
    <div class="absolute-center">
      <span v-if="quote" class="quote">{{ quote }}</span>
      <span v-if="author" class="author">- <a :href="authorLink">{{ author }}</a></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuoteMain',
  props: {
    quote: String,
    author: String,
    fullQuote: Object
  },
  computed: {
    authorLink() {
      const auth = this.author.trim().replaceAll(' ', "+");
      return `https://www.google.com/search?q=${auth}`;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.quote-wrapper {
  position: relative;
  height: 100vh;
  padding: 20px 20px;
  font-size: 24px;
}

.author {
  display: block;
  font-size: 16px;
  margin-top: 50px;
  font-style: italic;
}

/* .quote span {
  display: block;
  padding-bottom: 50px;
} */

.absolute-center {
  max-width: 500px;
  min-width: 400px;
  height: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
