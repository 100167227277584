<template>
  <div class="wrapper" :class="dark ? 'body-dark' : 'body-light'">
    <button class="button-2" @click="toggleDark"><img :src="darkModeImg" /></button>
    <button class="button-2" @click="getQuote"><img src="./assets/refresh.png" /></button>
    <button @click="openLink(tweetLink)"><img src="./assets/twitter.svg" /></button>
    <QuoteMain :fullQuote="fullQuote" :quote="quote" :author="author" />
  </div>
</template>
<script>
import QuoteMain from './components/QuoteMain.vue'
import axios from 'axios';
export default {
  name: 'App',
  components: {
    QuoteMain
  },
  data() {
    return {
      quote: '',
      author: '',
      fullQuote: {},
      dark: false,
    }
  },
  async created() {
    this.dark = localStorage.getItem('dark') == 'true';
    await this.getQuote();
  },
  computed: {
    darkModeImg() {
      return this.dark ? require('./assets/light-mode.png') : require('./assets/night-mode.png');
    },
    tweetLink() {
      const text = this.quote.replaceAll(" ", "%20");
      const author = this.author.replaceAll(" ", "%20");
      return `https://twitter.com/intent/tweet?text=${text}%20-%20${author}`;
    }
  },
  methods: {
    async getQuote() {
      const options = {
        method: "GET",
        url: `https://api.quotable.io/quotes/random`,
        headers: {
          "X-Api-Key": "cwYnFGeBClOqH/qF94PJnQ==RS5haltMHkbGARDV"
        },
        limit: 1
      }
      const response = await axios(options);
      this.fullQuote = response.data[0];
      this.quote = response.data[0].content;
      this.author = response.data[0].author;
    },
    toggleDark() {
      this.dark = !this.dark;
      localStorage.setItem('dark', this.dark);
    },
    openLink(link) {
      window.location.href = link;
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

#app {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.wrapper {
  padding: 0px 20px;
}

body {
  padding: 0;
  margin: 0;
}

img {
  width: 20px;
}

.body-dark {
  background-color: black;
  color: white;

  a {
    color: white;
  }

  a::before {
    background-color: #FAA613;
  }

  // button {
  //   background-color: rgba(51, 51, 51, 0.05);
  //   color: white;
  // }
}

.body-light {
  color: #18272F;

  a {
    color: #18272F;
  }

  a::before {
    background-color: #FAA613;
  }

  // button {
  //   background-color: rgba(51, 51, 51, 0.05);
  //   color: #333333;
  // }
}

a {
  position: relative;
  text-decoration: none;
  border-bottom: 2px solid #FAA613;
  padding: 2px;
}

a:hover {
  border-bottom: 3px solid #eb9b0f;
}

// a::before {
//   content: '';
//   position: absolute;
//   width: 100%;
//   height: 3px;
//   border-radius: 3px;

//   bottom: 0;
//   left: 0;
//   top: 18px;
//   transform-origin: right;
//   transform: scaleX(0);
//   transition: transform .3s ease-in-out;
// }

// a:hover::before {
//   transform-origin: left;
//   transform: scaleX(1);
// }


button {
  width: 40px;
  height: 40px;
  padding: 8px;
  margin: 10px;
  border-radius: 5px;
  border: 2.5px solid #E0E1E4;
  box-shadow: 0px 0px 20px -20px;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s ease-in-out 0ms;
  user-select: none;
  font-family: 'Poppins', sans-serif;
}

button:hover {
  background-color: #F2F2F2;
  box-shadow: 0px 0px 20px -18px;
}

button:active {
  transform: scale(0.95);
}
</style>
